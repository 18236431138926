<template>
    <div id="box">
        <Header :headerIndex="4"></Header>
        <div class="container bgf">
            <div class="history_box m1340">
                <div class="fs24 col10 fwb pb30">HISTORY</div>
                <div class="scroll">
                    <div class="history_list">
                        <div class="title_box flex-box fs16 col3">
                        <div class="title_item">Date</div>
                        <div class="title_item">Card Making Amount</div>
                        <div class="title_item">Invitation Code</div>
                        <div class="title_item">Invitation Link</div>
                        <div class="title_item">Operate</div>
                        <div class="title_item">Register ID</div>
                    </div>
                    <div class="list_box flex-box fs16 col3" v-for="item in list">
                        <div class="list_item single-line">{{ item.createtime_text }}</div>
                        <div class="list_item ">{{item.num}}</div>
                        <div class="list_item colb">{{item.invitecode}}</div>
                        <div class="list_item single-line"><a href="javascript:;" class="colb">{{item.url}}</a></div>
                        <div class="list_item"><a href="javascript:;" class="btn copy" @click="copy(item)">COPY</a></div>
                        <div class="list_item ">{{item.beregisteruser_id}}</div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
export default {
    data() {
        return {
            list:{}
        };
    },
    created(){
        this.getlist()
    },
    methods: {
        getlist(){
            this.$api.apiDocardlog({}).then(ret=>{
                if(ret.code==1){
                    this.list=ret.data
                }else{
                    // this.$message.error(ret.msg)
                    this.$message({
                        message: ret.msg,
                        type: 'error',
                        offset: window.screen.height / 2
                    })

                }
            })
        },
        copy (item) {
            //创建input标签
            // var input = document.createElement('input')
            //将input的值设置为需要复制的内容
        //    var text ='链接:'+item.url+'\n邀请码：'+ item.invitecode;
        //     document.execCommand(text); // 执行浏
            //添加input标签
            // document.body.appendChild(input)
            // //选中input标签
            // input.select()
            // //执行复制
            // document.execCommand('copy')
            // //成功提示信息
            // this.$message.success('success!')
            // //移除input标签
             // document.body.removeChild(input)
            //创建input标签
            var input = document.createElement('input')
            //将input的值设置为需要复制的内容
            input.value ='链接:'+item.url+'\n邀请码：'+ item.invitecode;
            //添加input标签
            document.body.appendChild(input)
            //选中input标签
            input.select()
            //执行复制
            document.execCommand('copy')
            //成功提示信息
            // this.$message.success('success!')
            this.$message({
                message: "复制成功",
                type: 'success',
                offset: window.screen.height / 2
            })
            //移除input标签
            document.body.removeChild(input)
        },
    }
};
</script>
